import { Injectable } from '@angular/core';
import { IGlobalLoadingService } from '@core-interfaces/services/globalLoading';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class GlobalLoadingManager implements IGlobalLoadingService {
    private loadingSubject = new BehaviorSubject<boolean>(false);
    loading = this.loadingSubject.asObservable();
    
    setLoading(loading: boolean): void {
        this.loadingSubject.next(loading);
    }   
    
}